var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "cnpj-corretora" }, [
        _c("div", { staticClass: "vx-row px-8 mb-5" }, [
          _c(
            "h4",
            { staticClass: "vx-col w-full flex items-center font-semibold" },
            [_vm._v("\n        Digite o CNPJ da corretora\n      ")]
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full mt-5" },
            [
              _c("ValidationObserver", [
                _c(
                  "form",
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "CNPJ", rules: "required|cnpj" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["##.###.###/####-##"],
                                    expression: "['##.###.###/####-##']",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  size: "large",
                                  placeholder: "CNPJ",
                                  disabled: _vm.invalid,
                                },
                                on: {
                                  input: _vm.atualizarCnpjCorretora,
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.cnpjCorretoraComponente,
                                  callback: function ($$v) {
                                    _vm.cnpjCorretoraComponente = $$v
                                  },
                                  expression: "cnpjCorretoraComponente",
                                },
                              }),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors[0],
                                      expression: "errors[0]",
                                    },
                                  ],
                                  staticClass: "text-danger mt-2 pl-3",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(errors[0]) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.modalEmailRecuperacaoSenhaComponente,
            "before-close": _vm.fecharModalEmailRecuperacaoSenha,
            width: "50%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalEmailRecuperacaoSenhaComponente = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("i", {
              staticClass: "el-icon-warning",
              staticStyle: { color: "#f29d00", "font-size": "43px" },
            }),
            _c("br"),
            _c(
              "h1",
              {
                staticStyle: {
                  color: "#585858",
                  "font-size": "35px",
                  "font-weight": "700",
                },
              },
              [_vm._v("\n        Atenção!\n      ")]
            ),
            _c(
              "h2",
              { staticStyle: { color: "#646464", "margin-top": "20px" } },
              [
                _vm._v(
                  "\n        A corretora já encontra-se cadastrada no sistema!\n      "
                ),
              ]
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-bottom": "12px",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    "\n        Deseja reenviar o e-mail de cadastro de senha, clique aqui\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                { on: { click: _vm.fecharModalEmailRecuperacaoSenha } },
                [_vm._v("Cancelar")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.enviarEmailRecuperacaoSenha },
                },
                [_vm._v("Enviar senha")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }