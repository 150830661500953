var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dados-usuario" },
    [
      _c("div", { staticClass: "vx-row px-12" }, [
        _c(
          "h4",
          { staticClass: "vx-col w-full flex items-center font-semibold p-0" },
          [
            _c("span", { staticClass: "block" }, [
              _vm._v("Dados dos Usuários"),
            ]),
            _c(
              "vx-tooltip",
              { attrs: { text: _vm.text.textUsuario, position: "right" } },
              [
                _c("i", { staticClass: "material-icons pl-2" }, [
                  _vm._v("info"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      (_vm.dadosCorretora.Users || []).length == 0
        ? _c("div", { staticClass: "vx-row px-12" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full p-0 mt-3" },
              [
                _c(
                  "vs-alert",
                  { staticClass: "h-auto mt-2", attrs: { color: "warning" } },
                  [
                    _vm._v(
                      "\n        Clique no botão de adicionar usuários para cadastrar um novo usuário\n        corretor.\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._l(_vm.dadosCorretora.Users, function (corretor, index) {
        return _c(
          "vs-row",
          { key: index, staticClass: "px-12" },
          [
            _c(
              "vs-col",
              { staticClass: "p-0", attrs: { "vs-lg": "10", "vs-sm": "12" } },
              [
                _c(
                  "vs-row",
                  { staticClass: "m-0 p-0" },
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-sm": "12", "vs-lg": "6" } },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { "label-placeholder": "Nome", size: "large" },
                          model: {
                            value: corretor.Name,
                            callback: function ($$v) {
                              _vm.$set(corretor, "Name", $$v)
                            },
                            expression: "corretor.Name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { attrs: { "vs-sm": "12", "vs-lg": "6" } },
                      [
                        _c("ValidationProvider", {
                          attrs: { name: "E-mail do usuário", rules: "email" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        "label-placeholder": "E-mail",
                                        size: "large",
                                      },
                                      model: {
                                        value: corretor.Email,
                                        callback: function ($$v) {
                                          _vm.$set(corretor, "Email", $$v)
                                        },
                                        expression: "corretor.Email",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]",
                                          },
                                        ],
                                        staticClass: "text-danger text-sm",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(errors[0]) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "vs-row",
                  { staticClass: "mt-5 p-0" },
                  [
                    _c(
                      "vs-col",
                      { attrs: { "vs-sm": "12", "vs-lg": "6" } },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##) #####-####"],
                              expression: "['(##) #####-####']",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "label-placeholder": "Celular para autenticação",
                            size: "large",
                          },
                          model: {
                            value: corretor.PhoneNumber,
                            callback: function ($$v) {
                              _vm.$set(corretor, "PhoneNumber", $$v)
                            },
                            expression: "corretor.PhoneNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      {
                        style: {
                          "align-self": "flex-end",
                          height: "48px !important",
                        },
                        attrs: { "vs-sm": "12", "vs-lg": "6" },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            label: "Name",
                            size: "large",
                            placeholder: "Tipo Perfil",
                            reduce: (perfil) => perfil.UniqueId,
                            options: _vm.tiposUsuario,
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          model: {
                            value: corretor.ProfileUniqueId,
                            callback: function ($$v) {
                              _vm.$set(corretor, "ProfileUniqueId", $$v)
                            },
                            expression: "corretor.ProfileUniqueId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vs-col",
              { attrs: { "vs-sm": "12", "vs-lg": "2" } },
              [
                _c("vs-button", {
                  staticClass: "delete mt-4 ml-2",
                  attrs: {
                    color: "primary",
                    type: "flat",
                    size: "large",
                    icon: "delete",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.removerCorretor(index)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full px-12" },
          [
            _c(
              "vs-button",
              {
                staticClass: "font-semibold mt-2 float-right",
                attrs: {
                  "icon-after": "",
                  color: "primary",
                  type: "flat",
                  icon: "add_circle",
                },
                on: {
                  click: function ($event) {
                    return _vm.adicionarCorretor()
                  },
                },
              },
              [_vm._v("\n        Adicionar usuário\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }