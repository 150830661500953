<template>
  <div>
    <div class="cnpj-corretora">
      <div class="vx-row px-8 mb-5">
        <h4 class="vx-col w-full flex items-center font-semibold">
          Digite o CNPJ da corretora
        </h4>
        <div class="vx-col w-full mt-5">
          <ValidationObserver>
            <form>
              <ValidationProvider
                name="CNPJ"
                rules="required|cnpj"
                v-slot="{ errors }"
              >
                <vs-input
                  size="large"
                  class="w-full"
                  placeholder="CNPJ"
                  v-mask="['##.###.###/####-##']"
                  v-model="cnpjCorretoraComponente"
                  @input="atualizarCnpjCorretora"
                  :disabled="invalid"
                  @keydown.enter.prevent
                />

                <p class="text-danger mt-2 pl-3" v-show="errors[0]">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="modalEmailRecuperacaoSenhaComponente"
      :before-close="fecharModalEmailRecuperacaoSenha"
      width="50%"
      center
    >
      <div class="text-center">
        <i class="el-icon-warning" style="color: #f29d00; font-size: 43px;"></i>
        <br />
        <h1 style="color: #585858; font-size: 35px; font-weight: 700;">
          Atenção!
        </h1>

        <h2 style="color: #646464; margin-top: 20px;">
          A corretora já encontra-se cadastrada no sistema!
        </h2>
      </div>

      <span slot="footer" class="dialog-footer">
        <p style="margin-bottom: 12px; font-weight: 500;">
          Deseja reenviar o e-mail de cadastro de senha, clique aqui
        </p>
        <el-button @click="fecharModalEmailRecuperacaoSenha"
          >Cancelar</el-button
        >
        <el-button type="primary" @click="enviarEmailRecuperacaoSenha"
          >Enviar senha</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mapfreProvider from "@/providers/mapfre-provider.js";
import * as Utils from "@/utils/utils";

import { mask } from "vue-the-mask";

export default {
  name: "cadastro-cnpj-corretora",
  directives: { mask },
  props: {
    cnpjCorretora: String,
    exibirModalEmailRecuperacaoSenha: Boolean
  },
  data() {
    return {
      cnpjCorretoraComponente: "",
      modalEmailRecuperacaoSenhaComponente: false
    };
  },
  computed: {},
  watch: {
    cnpjCorretora(newState) {
      this.cnpjCorretoraComponente = newState;
    },

    exibirModalEmailRecuperacaoSenha(newState) {
      this.modalEmailRecuperacaoSenhaComponente = newState;
    }
  },
  methods: {
    atualizarCnpjCorretora() {
      this.$emit("atualizarCnpjCorretora", this.cnpjCorretoraComponente);
    },

    async enviarEmailRecuperacaoSenha() {
      const data = {
        person: {
          CpfCnpj: Utils.removeSpecialChars(this.cnpjCorretoraComponente)
        }
      };

      await this.$onpoint.loadingModal(
        "Processando sua solicitação",
        async () => {
          return await mapfreProvider
            .enviarEmailRecuperacaoSenha(data)
            .then(() => {
              this.$onpoint.successModal(
                "Link de recuperação de senha enviado. Enviamos um link de recuperação de senha para o seu e-mail."
              );
            })
            .then(async () => {
              await this.fecharModalEmailRecuperacaoSenha();
            })
            .catch(errors =>
              this.$onpoint.errorModal(errors.response.data.Errors)
            );
        }
      );
    },

    fecharModalEmailRecuperacaoSenha() {
      (this.cnpjCorretoraComponente = ""),
        (this.modalEmailRecuperacaoSenhaComponente = false);
      this.$emit("atualizarCnpjCorretora", this.cnpjCorretoraComponente);
      this.$emit(
        "atualizarModalEmailRecuperacaoSenha",
        this.modalEmailRecuperacaoSenhaComponente
      );
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "cadastro-cnpj-corretora",
      url: window.location.href
    });
  }
};
</script>

<style scoped lang="scss"></style>
