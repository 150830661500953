<template>
  <div class="dados-bancarios">
    <div class="vx-row px-12 mb-5">
      <h4 class="vx-col w-full flex items-center font-semibold p-0">
        <span class="block">Dados Bancários da Corretora</span>
        <vx-tooltip :text="text.textDadosCorretora" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
      <div class="vx-col w-1/2 mt-5 p-0 pr-2">
        <v-select
          label="Name"
          size="large"
          placeholder="Selecione o banco"
          class="w-full"
          v-model="dadosCorretora.BankId"
          :options="bancos"
          :reduce="banco => banco.Id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <div class="vx-col w-1/2 mt-5 p-0">
        <v-select
          label="Name"
          size="large"
          placeholder="Selecione o tipo da conta"
          class="w-full"
          v-model="dadosCorretora.BankAccountTypeId"
          :options="tiposDeContas"
          :reduce="tipoDeConta => tipoDeConta.Id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <div class="flex flex-col md:flex-row w-full">
        <div class=" flex flex-row w-full md:w-1/2 mt-5 md:pr-2">
          <div class="w-1/2 pr-2">
            <vs-input
              label-placeholder="Agência"
              size="large"
              v-mask="['###', '####', '#####', '######', '#######']"
              v-model="dadosCorretora.BankBranchNumber"
              class="w-full"
            />
          </div>
          <div class="w-1/2">
            <vs-input
              label-placeholder="Dígito"
              size="large"
              v-mask="['#']"
              v-model="dadosCorretora.BankBranchDigit"
              class="w-full"
            />
          </div>
        </div>

        <div class="flex flex-row w-full md:w-1/2 mt-5">
          <div class="w-1/2 pr-2">
            <vs-input
              label-placeholder="Conta"
              size="large"
              v-mask="[
                '####',
                '#####',
                '######',
                '#######',
                '####################'
              ]"
              v-model="dadosCorretora.BankAccountNumber"
              class="w-full"
            />
          </div>
          <div class="w-1/2">
            <vs-input
              label-placeholder="Digito"
              size="large"
              v-mask="['#']"
              v-model="dadosCorretora.BankAccountDigit"
              class="w-full"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row px-12 mb-5 mt-12">
      <h4 class="vx-col w-full flex items-center font-semibold p-0">
        <span class="block">Dados do Responsável Financeiro</span>
        <vx-tooltip :text="text.textDadosResponsavel" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0">
        <vs-input
          label-placeholder="Nome Completo"
          size="large"
          v-model="(dadosCorretora.FinancialOfficer || {}).Name"
          class="w-full"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0">
        <ValidationProvider name="CPF" rules="cpf" v-slot="{ errors }">
          <vs-input
            label-placeholder="CPF"
            size="large"
            v-model="(dadosCorretora.FinancialOfficer || {}).CpfCnpj"
            v-mask="'###.###.###-##'"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0">
        <ValidationProvider name="E-mail" rules="email" v-slot="{ errors }">
          <vs-input
            label-placeholder="E-mail"
            type="email"
            size="large"
            v-model="(dadosCorretora.FinancialOfficer || {}).Email"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0">
        <ValidationProvider
          name="Celular"
          :rules="{
            regex: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
          }"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="Celular para autenticação"
            size="large"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="(dadosCorretora.FinancialOfficer || {}).PhoneNumber"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "dados-bancarios-corretora",
  directives: { mask },
  props: {
    dadosCorretora: {
      type: Object,
      required: true
    },
    bancos: {
      type: Array,
      required: true
    },
    tiposDeContas: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      text: {
        textDadosCorretora:
          "Inclua dados bancários para efetuar pagamento de comissão",
        textDadosResponsavel:
          "Dados do responsável financeiro da Corretora para contato da Seguradora"
      }
    };
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="scss">
.dados-bancarios {
  h4 {
    color: rgba(var(--vs-primary), 1);

    i {
      color: rgba(var(--vs-warning), 1);
      display: block;
      font-size: 0.8em !important;
    }
  }
}
</style>
