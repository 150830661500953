<template>
  <section v-if="permissoes">
    <div class="m-1 mr-4 px-0 cadastrar-novo-corretor">
      <Breadcrumb
        title="Corretores"
        actualPage="Cadastrar novo corretor"
        previousPage="corretores"
        previousPageTitle="Lista de Corretores"
      />
    </div>

    <template>
      <form-wizard
        color="rgba(var(--vs-warning), 1)"
        :title="null"
        :subtitle="null"
        finishButtonText="Submit"
        class="cadastrar-novo-corretor"
        @on-complete="concluirCadastro"
      >
        <!-- tab 1 content -->
        <tab-content class="mb-8 mt-8">
          <CadastroCorretoraCNPJ
            @atualizarCnpjCorretora="atualizarCnpjCorretora"
            @atualizarModalEmailRecuperacaoSenha="
              atualizarModalEmailRecuperacaoSenha
            "
            :cnpjCorretora="cnpjCorretora"
            :exibirModalEmailRecuperacaoSenha="exibirModalEmailRecuperacaoSenha"
          />
        </tab-content>

        <!-- tab 2 content -->
        <tab-content class="mb-8 mt-8">
          <DadosCorretoraAdmPortal
            :podeAlterarCodigoCorretor="podeAlterarCodigoCorretor"
            :podeAlterarCodigoSucursal="podeAlterarCodigoSucursal"
            :podeAlterarCodigoContrato="podeAlterarCodigoContrato"
            :tiposDeCanais="tiposDeCanais"
            :podeAlterarCanal="podeAlterarCanal"
            :dadosCorretora="dadosCorretora"
            :dadosComplementaresCorretora="dadosComplementaresCorretora"
          />
        </tab-content>

        <!-- tab 3 content -->
        <tab-content class="mb-8 mt-8">
          <DadosBancariosRespFinanceiro
            :dadosCorretora="dadosCorretora"
            :bancos="bancos"
            :tiposDeContas="tiposDeContas"
          />
        </tab-content>

        <!-- tab 4 content -->
        <tab-content class="mb-8 mt-8">
          <DadosUsuario :dadosCorretora="dadosCorretora" />
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div>
            <div class="wizard-footer-left">
              <vs-button
                v-if="props.activeTabIndex > 1"
                @click.native="props.prevTab()"
                color="primary"
              >
                <i class="onpoint-arrow-left icon-font"></i>
                <span class="font-semibold pr-2 pl-2">Voltar</span>
              </vs-button>
            </div>
            <div class="wizard-footer-right">
              <vs-button
                v-if="props.activeTabIndex === 0"
                @click="iniciarCadastro(props)"
                :disabled="!habilitarBtnIniciarCadastro"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Iniciar cadastro
                </span>
              </vs-button>

              <vs-button
                v-else
                @click.native="avancarPagina(props) ? props.nextTab() : ''"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  {{ props.isLastStep ? "Concluir cadastro" : "Proxima etapa" }}
                </span>
                <i class="onpoint-arrow-right icon-font" v-if="!props.isLastStep"></i>
              </vs-button>
            </div>
          </div>
        </template>
      </form-wizard>
    </template>
  </section>
</template>

<script>
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import CadastroCorretoraCNPJ from "@/views/cadastro-corretor-seguradora/components/CadastroCorretoraCNPJ.vue"
import DadosCorretoraAdmPortal from "@/views/cadastro-corretor-seguradora/components/DadosCorretoraAdmPortal.vue";
import DadosBancariosRespFinanceiro from "@/views/cadastro-corretor-seguradora/components/DadosBancariosRespFinanceiro.vue";
import DadosUsuario from "@/views/cadastro-corretor-seguradora/components/DadosUsuarioCorretora.vue";

import * as Permissoes from "@/utils/permissions.js";
import mapfreProvider from "@/providers/mapfre-provider.js";

import * as Utils from "@/utils/utils";

export default {
  name: "cadastrar-novo-corretor",
  components: {
    Breadcrumb,
    CadastroCorretoraCNPJ,
    DadosCorretoraAdmPortal,
    DadosBancariosRespFinanceiro,
    DadosUsuario
  },
  data() {
    return {
      permissoes: [],
      cnpjCorretora: "",
      exibirModalEmailRecuperacaoSenha: false,
      dadosCorretora: {},
      dadosComplementaresCorretora: {
        CodigoCorretor: "",
        CodigoSurcursal: "",
        CodigoContrato: "",
        TipoCanalId: ""
      },
      tiposDeCanais: [],
      bancos: [],
      tiposDeContas: []
    };
  },
  computed: {
    podeCadastrarNovoCorretor() {
      return this.permissoes.includes("ExibirCadastrarNovo");
    },
    podeAlterarCodigoCorretor() {
      return this.permissoes.includes("AlterarCodigoCorretor");
    },
    podeAlterarCodigoSucursal() {
      return this.permissoes.includes("AlterarCodigoSucursal");
    },
    podeAlterarCodigoContrato() {
      return this.permissoes.includes("AlterarCodigoContrato");
    },
    podeAlterarCanal() {
      return this.permissoes.includes("AlterarCanal");
    },
    habilitarBtnIniciarCadastro: {
      get() {
        return this.cnpjCorretora && this.cnpjCorretora.length === 14
          ? true
          : false;
      }
    }
  },
  methods: {
    async obterModuloPermissoes(modulo) {
      this.permissoes = [];
      try {
        this.permissoes = await mapfreProvider.obterPermissoesModulo(
          modulo
        );
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    atualizarCnpjCorretora(event) {
      this.cnpjCorretora = Utils.removeSpecialChars(event);
    },

    atualizarModalEmailRecuperacaoSenha(event) {
      this.exibirModalEmailRecuperacaoSenha = event;
    },

    async iniciarCadastro(props) {
      let cnpj = this.cnpjCorretora;

      if (!Utils.isValidCnpj(cnpj)) {
        this.$onpoint.errorModal("CNPJ inválido");
        this.cnpjCorretora = "";
        return;
      }

      await this.$onpoint.loadingModal(
        "Aguarde, seus dados estão sendo processados",
        async () => {
          return await mapfreProvider
            .buscarInformacoesCorretora(cnpj)
            .then(response => {
              this.dadosCorretora = { ...response, InscricaoMunicipal: "" };
            })
            .then(() => {
              props.nextTab();
            })
            .catch(errors => {
              if (
                errors.response.data.Errors[0] ===
                "Erro! A corretora já encontra-se cadastrada no sistema!"
              ) {
                this.exibirModalEmailRecuperacaoSenha = true;
              } else {
                this.$onpoint.errorModal(errors.response.data.Errors);
                this.cnpjCorretora = "";
              }
            });
        }
      );
    },

    async pegarTipoCanal() {
      this.tiposDeCanais = [];
      let somenteCadastro = false;
      try {
        this.tiposDeCanais = await mapfreProvider.listarTiposCanais(
          somenteCadastro
        );
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async pegarListaBancos() {
      this.bancos = [];
      try {
        this.bancos = await mapfreProvider.listarBancos();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async pegarListaTiposDeContas() {
      this.tiposDeContas = [];
      try {
        this.tiposDeContas = await mapfreProvider.listarTiposDeContas();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    avancarPagina(props) {
      if (props.activeTabIndex === 1) {
        return this.verificarCamposDadosCorretoraAdmPortal();
      }
      if (props.activeTabIndex === 2) {
        return this.verificarComposDadosBancariosRespFinanceiro();
      }
      return true;
    },

    verificarCamposDadosCorretoraAdmPortal() {
      let IM = this.dadosCorretora.InscricaoMunicipal;
      let admNome = this.dadosCorretora.Administrator.Name;
      let admEmail = this.dadosCorretora.Administrator.Email;
      let admCel = this.dadosCorretora.Administrator.PhoneNumber;

      let codigoCorretor = this.podeAlterarCodigoCorretor
        ? this.dadosComplementaresCorretora.CodigoCorretor
        : "";
      let codigoSucursal = this.podeAlterarCodigoSucursal
        ? this.dadosComplementaresCorretora.CodigoSurcursal
        : "";
      let codigoContrato = this.podeAlterarCodigoContrato
        ? this.dadosComplementaresCorretora.CodigoContrato
        : "";
      let tipoCanal = this.podeAlterarCanal
        ? this.dadosComplementaresCorretora.TipoCanalId
        : "";

      let errosDadosComplementaresCorretora = {
        CodigoCorretor: codigoCorretor ? "" : "Código do corretor Mapfre",
        CodigoSucursal: codigoSucursal ? "" : "Código da sucursal",
        CodigoContrato: codigoContrato ? "" : "Código do contrato"
      };

      if (!IM || !admNome || !admEmail || !admCel) {
        this.$onpoint.errorModal("Todos os campos precisam ser preenchidos");
        return false;
      } else if (!Utils.validEmail(admEmail)) {
        this.$onpoint.errorModal("E-mail inválido");
        return false;
      } else if (!tipoCanal) {
        this.$onpoint.errorModal(
          "Campo 'Selecione o canal' precisa ser preenchido"
        );
        return false;
      } else if (tipoCanal === 1) {
        if (!codigoCorretor || !codigoSucursal || !codigoContrato) {
          this.$onpoint.errorModal([
            "Quando Canal Mapfre escolhido, os campos a seguir devem ser preenchidos:",
            errosDadosComplementaresCorretora.CodigoCorretor,
            errosDadosComplementaresCorretora.CodigoSucursal,
            errosDadosComplementaresCorretora.CodigoContrato
          ]);
          return false;
        } else return true;
      } else {
        return true;
      }
    },

    verificarComposDadosBancariosRespFinanceiro() {
      let banco = this.dadosCorretora.BankId;
      let contaTipo = this.dadosCorretora.BankAccountTypeId;
      let contaAgencia = this.dadosCorretora.BankBranchNumber;
      let ContaConta = this.dadosCorretora.BankAccountNumber;
      let responsavelNome = this.dadosCorretora.FinancialOfficer.Name;
      let responsavelCPF = this.dadosCorretora.FinancialOfficer.CpfCnpj;
      let responsavelEmail = this.dadosCorretora.FinancialOfficer.Email;
      let responsavelCel = this.dadosCorretora.FinancialOfficer.PhoneNumber;

      if (
        !banco ||
        !contaTipo ||
        !contaAgencia ||
        !ContaConta ||
        !responsavelNome ||
        !responsavelCPF ||
        !responsavelEmail ||
        !responsavelCel
      ) {
        this.$onpoint.errorModal("Todos os campos precisam ser preenchidos");
        return false;
      } else if (!this.$utils.isValidCpf(responsavelCPF)) {
        this.$onpoint.errorModal("CPF inválido");
        return false;
      } else if (!this.$utils.validEmail(responsavelEmail)) {
        this.$onpoint.errorModal("E-mail inválido");
        return false;
      } else {
        return true;
      }
    },

    async concluirCadastro() {
      let dadosParaConcluirCadastro = {
        ...this.dadosCorretora,
        ...this.dadosComplementaresCorretora
      };

      await this.$onpoint.loadingModal(
        "Aguarde, seus dados estão sendo processados",
        async () => {
          return await mapfreProvider
            .cadastrarNovaCorretora(dadosParaConcluirCadastro)
            .then(() => {
              this.$onpoint.successModal(
                "Seu cadastro foi concluído com sucesso. Você receberá um e-mail com as instruções de acesso"
              );
              setTimeout(() => {
                this.$router.push("/corretores");
              }, 2300);
            })
            .catch(errors => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        }
      );
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "cadastro-corretor-seguradora",
      url: window.location.href
    });
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.obterModuloPermissoes(Permissoes.Modulo.CORRETOR);
      await this.pegarTipoCanal();
      await this.pegarListaBancos();
      await this.pegarListaTiposDeContas();
    });
  }
};
</script>

<style lang="scss">
.cadastrar-novo-corretor {
  .wizard-tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 2em 0 auto;
    background-color: #fff !important;
  }

  .wizard-card-footer {
    min-height: 70px;
    padding: 0 27px !important;
    background-color: #fff !important;
  }

  .wizard-footer-left .vs-button {
    padding: 0.75rem 1.2rem !important;
  }

  h4 {
    color: rgba(var(--vs-primary), 1);

    i {
      color: rgba(var(--vs-warning), 1);
      display: block;
      font-size: 0.8em;
    }
  }
}
</style>

<style lang="scss" scoped>
  .icon-font::before{
    position: relative;
    top: 2px;
    font-size:16px;
  }
</style>
