var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dados-bancarios" }, [
    _c("div", { staticClass: "vx-row px-12 mb-5" }, [
      _c(
        "h4",
        { staticClass: "vx-col w-full flex items-center font-semibold p-0" },
        [
          _c("span", { staticClass: "block" }, [
            _vm._v("Dados Bancários da Corretora"),
          ]),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.text.textDadosCorretora, position: "right" } },
            [_c("i", { staticClass: "material-icons pl-2" }, [_vm._v("info")])]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2 mt-5 p-0 pr-2" },
        [
          _c("v-select", {
            staticClass: "w-full",
            attrs: {
              label: "Name",
              size: "large",
              placeholder: "Selecione o banco",
              options: _vm.bancos,
              reduce: (banco) => banco.Id,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            model: {
              value: _vm.dadosCorretora.BankId,
              callback: function ($$v) {
                _vm.$set(_vm.dadosCorretora, "BankId", $$v)
              },
              expression: "dadosCorretora.BankId",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2 mt-5 p-0" },
        [
          _c("v-select", {
            staticClass: "w-full",
            attrs: {
              label: "Name",
              size: "large",
              placeholder: "Selecione o tipo da conta",
              options: _vm.tiposDeContas,
              reduce: (tipoDeConta) => tipoDeConta.Id,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            model: {
              value: _vm.dadosCorretora.BankAccountTypeId,
              callback: function ($$v) {
                _vm.$set(_vm.dadosCorretora, "BankAccountTypeId", $$v)
              },
              expression: "dadosCorretora.BankAccountTypeId",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex flex-col md:flex-row w-full" }, [
        _c(
          "div",
          { staticClass: "flex flex-row w-full md:w-1/2 mt-5 md:pr-2" },
          [
            _c(
              "div",
              { staticClass: "w-1/2 pr-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["###", "####", "#####", "######", "#######"],
                      expression:
                        "['###', '####', '#####', '######', '#######']",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { "label-placeholder": "Agência", size: "large" },
                  model: {
                    value: _vm.dadosCorretora.BankBranchNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.dadosCorretora, "BankBranchNumber", $$v)
                    },
                    expression: "dadosCorretora.BankBranchNumber",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-1/2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["#"],
                      expression: "['#']",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { "label-placeholder": "Dígito", size: "large" },
                  model: {
                    value: _vm.dadosCorretora.BankBranchDigit,
                    callback: function ($$v) {
                      _vm.$set(_vm.dadosCorretora, "BankBranchDigit", $$v)
                    },
                    expression: "dadosCorretora.BankBranchDigit",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "flex flex-row w-full md:w-1/2 mt-5" }, [
          _c(
            "div",
            { staticClass: "w-1/2 pr-2" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: [
                      "####",
                      "#####",
                      "######",
                      "#######",
                      "####################",
                    ],
                    expression:
                      "[\n              '####',\n              '#####',\n              '######',\n              '#######',\n              '####################'\n            ]",
                  },
                ],
                staticClass: "w-full",
                attrs: { "label-placeholder": "Conta", size: "large" },
                model: {
                  value: _vm.dadosCorretora.BankAccountNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.dadosCorretora, "BankAccountNumber", $$v)
                  },
                  expression: "dadosCorretora.BankAccountNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-1/2" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["#"],
                    expression: "['#']",
                  },
                ],
                staticClass: "w-full",
                attrs: { "label-placeholder": "Digito", size: "large" },
                model: {
                  value: _vm.dadosCorretora.BankAccountDigit,
                  callback: function ($$v) {
                    _vm.$set(_vm.dadosCorretora, "BankAccountDigit", $$v)
                  },
                  expression: "dadosCorretora.BankAccountDigit",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "vx-row px-12 mb-5 mt-12" }, [
      _c(
        "h4",
        { staticClass: "vx-col w-full flex items-center font-semibold p-0" },
        [
          _c("span", { staticClass: "block" }, [
            _vm._v("Dados do Responsável Financeiro"),
          ]),
          _c(
            "vx-tooltip",
            {
              attrs: { text: _vm.text.textDadosResponsavel, position: "right" },
            },
            [_c("i", { staticClass: "material-icons pl-2" }, [_vm._v("info")])]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { "label-placeholder": "Nome Completo", size: "large" },
            model: {
              value: (_vm.dadosCorretora.FinancialOfficer || {}).Name,
              callback: function ($$v) {
                _vm.$set(_vm.dadosCorretora.FinancialOfficer || {}, "Name", $$v)
              },
              expression: "(dadosCorretora.FinancialOfficer || {}).Name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0" },
        [
          _c("ValidationProvider", {
            attrs: { name: "CPF", rules: "cpf" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "###.###.###-##",
                          expression: "'###.###.###-##'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { "label-placeholder": "CPF", size: "large" },
                      model: {
                        value: (_vm.dadosCorretora.FinancialOfficer || {})
                          .CpfCnpj,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dadosCorretora.FinancialOfficer || {},
                            "CpfCnpj",
                            $$v
                          )
                        },
                        expression:
                          "(dadosCorretora.FinancialOfficer || {}).CpfCnpj",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0" },
        [
          _c("ValidationProvider", {
            attrs: { name: "E-mail", rules: "email" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "E-mail",
                        type: "email",
                        size: "large",
                      },
                      model: {
                        value: (_vm.dadosCorretora.FinancialOfficer || {})
                          .Email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dadosCorretora.FinancialOfficer || {},
                            "Email",
                            $$v
                          )
                        },
                        expression:
                          "(dadosCorretora.FinancialOfficer || {}).Email",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "Celular",
              rules: {
                regex:
                  /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##) ####-####", "(##) #####-####"],
                          expression: "['(##) ####-####', '(##) #####-####']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "Celular para autenticação",
                        size: "large",
                      },
                      model: {
                        value: (_vm.dadosCorretora.FinancialOfficer || {})
                          .PhoneNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dadosCorretora.FinancialOfficer || {},
                            "PhoneNumber",
                            $$v
                          )
                        },
                        expression:
                          "(dadosCorretora.FinancialOfficer || {}).PhoneNumber",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }