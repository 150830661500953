<template>
  <div class="dados-corretora">
    <div class="vx-row px-12 mb-5">
      <h4 class="vx-col p-0 w-full flex items-center font-semibold">
        <span class="block mb-2">Dados da Corretora</span>
        <vx-tooltip :text="text.textCorretora" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
      <div class="vx-col w-full mt-5 p-0">
        <vs-input
          label-placeholder="Razão Social"
          size="large"
          :value="dadosCorretora.Name"
          disabled
          class="w-full"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0">
        <vs-input
          label-placeholder="CNPJ"
          size="large"
          :value="dadosCorretora.CpfCnpj | maskCnpj"
          class="w-full"
          disabled="true"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0">
        <vs-input
          label-placeholder="Nroº Susep"
          size="large"
          :value="dadosCorretora.SUSEPProtocol"
          class="w-full"
          disabled
        />
      </div>
      <div class="vx-col w-full mt-5 p-0">
        <vs-input
          label-placeholder="Inscrição Municipal"
          type="text"
          size="large"
          v-model="dadosCorretora.InscricaoMunicipal"
          class="w-full"
        />
      </div>

      <div class="flex flex-col md:flex-row w-full">
        <div class=" flex flex-row w-full mt-5 ">
          <div class="w-1/4 pr-2" v-if="podeAlterarCodigoCorretor">
            <vs-input
              label-placeholder="Código do corretor Mapfre"
              size="large"
              v-model="dadosComplementaresCorretora.CodigoCorretor"
              class="w-full"
              maxlength="10"
              @input="validarNumeros($event, 'dadosComplementaresCorretora.CodigoCorretor')"
            />
          </div>
          <div class="w-1/4 pr-2" v-if="podeAlterarCodigoSucursal">
            <vs-input
              label-placeholder="Código da sucursal"
              size="large"
              v-model="dadosComplementaresCorretora.CodigoSurcursal"
              class="w-full"
              maxlength="10"
              @input="validarNumeros($event, 'dadosComplementaresCorretora.CodigoSurcursal')"
            />
          </div>
          <div class="w-1/4 pr-2" v-if="podeAlterarCodigoContrato">
            <vs-input
              label-placeholder="Código do contrato"
              size="large"
              v-model="dadosComplementaresCorretora.CodigoContrato"
              class="w-full"
              maxlength="10"
              @input="validarNumeros($event, 'dadosComplementaresCorretora.CodigoContrato')"
            />
          </div>
          <div
            class="w-1/4"
            :style="{ 'align-self': 'flex-end', height: '48px !important' }"
            v-if="podeAlterarCanal"
          >
            <v-select
              label="Descricao"
              placeholder="Selecione o canal"
              size="large"
              class="w-full"
              :value="dadosComplementaresCorretora.TipoCanalId"
              v-model="dadosComplementaresCorretora.TipoCanalId"
              :reduce="tipoCanal => tipoCanal.Id"
              :options="tiposDeCanais"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="vx-row px-12 mb-5"
      v-if="(dadosCorretora.SUSEPProtocol || '').length == 0"
    >
      <div class="vx-col w-full mt-12 p-0">
        <vs-alert
          title="Recadastramento SUSEP"
          color="rgb(231, 154, 23)"
          active="true"
        >
          Atenção, identificamos que você não está recadastrado na SUSEP.
          Verifique seu cadastro no site da Superintendência de Seguros
          Privados.
          <a href="http://susep.gov.br" target="_blank">http://susep.gov.br</a>
        </vs-alert>
      </div>
    </div>

    <div class="vx-row px-12 mb-5 mt-12">
      <h4 class="vx-col w-full flex items-center font-semibold p-0">
        <span class="block mb-2">Dados do administrador do Portal</span>
        <vx-tooltip :text="text.textAdministrador" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip>
      </h4>
      <div class="vx-col w-full mt-5 p-0">
        <ValidationProvider
          name="Nome do administrador"
          rules="required"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="Nome do administrador"
            size="large"
            v-model="(dadosCorretora.Administrator || {}).Name"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 p-0">
        <ValidationProvider
          name="E-mail"
          rules="required|email"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="E-mail"
            type="email"
            size="large"
            v-model="(dadosCorretora.Administrator || {}).Email"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0">
        <ValidationProvider
          name="Celular"
          :rules="{
            regex: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
          }"
          v-slot="{ errors }"
        >
          <vs-input
            label-placeholder="Celular para autenticação"
            size="large"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="(dadosCorretora.Administrator || {}).PhoneNumber"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "dados-corretora",
  directives: { mask },
  props: {
    podeAlterarCodigoCorretor: {
      type: Boolean,
      required: true
    },
    podeAlterarCodigoSucursal: {
      type: Boolean,
      required: true
    },
    podeAlterarCodigoContrato: {
      type: Boolean,
      required: true
    },
    podeAlterarCanal: {
      type: Boolean,
      required: true
    },
    dadosCorretora: {
      type: Object,
      required: true
    },
    dadosComplementaresCorretora: {
      type: Object,
      required: true
    },
    tiposDeCanais: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      text: {
        textCorretora:
          "Dados de cadastro da Corretora obtidos automaticamente da Receita Federal.",
        textAdministrador:
          "Dados da pessoa física que terá autonomia para definir novos usuários na Plataforma Digital."
      },
      canais: [
        {
          Id: 1,
          Name: "Canal Mapfre"
        },
        {
          Id: 2,
          Name: "Canal BB"
        }
      ]
    };
  },
  watch: {},
  computed: {},
  methods: {
    validarNumeros(evento, caminho) {
      const valoresNumericos = evento.replace(/\D/g, "");

      let target = this;
      const parts = caminho.split(".");
      for (let i = 0; i < parts.length - 1; i++) {
        target = target[parts[i]];
      }
      target[parts[parts.length - 1]] = valoresNumericos;
    }
  }
};
</script>

<style lang="scss">
.dados-corretora {
  h4 {
    color: rgba(var(--vs-primary), 1);

    i {
      color: rgba(var(--vs-warning), 1);
      display: block;
      font-size: 0.8em !important;
    }
  }
}
</style>
