var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dados-corretora" }, [
    _c("div", { staticClass: "vx-row px-12 mb-5" }, [
      _c(
        "h4",
        { staticClass: "vx-col p-0 w-full flex items-center font-semibold" },
        [
          _c("span", { staticClass: "block mb-2" }, [
            _vm._v("Dados da Corretora"),
          ]),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.text.textCorretora, position: "right" } },
            [_c("i", { staticClass: "material-icons pl-2" }, [_vm._v("info")])]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mt-5 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              "label-placeholder": "Razão Social",
              size: "large",
              value: _vm.dadosCorretora.Name,
              disabled: "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              "label-placeholder": "CNPJ",
              size: "large",
              value: _vm._f("maskCnpj")(_vm.dadosCorretora.CpfCnpj),
              disabled: "true",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              "label-placeholder": "Nroº Susep",
              size: "large",
              value: _vm.dadosCorretora.SUSEPProtocol,
              disabled: "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mt-5 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              "label-placeholder": "Inscrição Municipal",
              type: "text",
              size: "large",
            },
            model: {
              value: _vm.dadosCorretora.InscricaoMunicipal,
              callback: function ($$v) {
                _vm.$set(_vm.dadosCorretora, "InscricaoMunicipal", $$v)
              },
              expression: "dadosCorretora.InscricaoMunicipal",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex flex-col md:flex-row w-full" }, [
        _c("div", { staticClass: "flex flex-row w-full mt-5" }, [
          _vm.podeAlterarCodigoCorretor
            ? _c(
                "div",
                { staticClass: "w-1/4 pr-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "label-placeholder": "Código do corretor Mapfre",
                      size: "large",
                      maxlength: "10",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validarNumeros(
                          $event,
                          "dadosComplementaresCorretora.CodigoCorretor"
                        )
                      },
                    },
                    model: {
                      value: _vm.dadosComplementaresCorretora.CodigoCorretor,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dadosComplementaresCorretora,
                          "CodigoCorretor",
                          $$v
                        )
                      },
                      expression: "dadosComplementaresCorretora.CodigoCorretor",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.podeAlterarCodigoSucursal
            ? _c(
                "div",
                { staticClass: "w-1/4 pr-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "label-placeholder": "Código da sucursal",
                      size: "large",
                      maxlength: "10",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validarNumeros(
                          $event,
                          "dadosComplementaresCorretora.CodigoSurcursal"
                        )
                      },
                    },
                    model: {
                      value: _vm.dadosComplementaresCorretora.CodigoSurcursal,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dadosComplementaresCorretora,
                          "CodigoSurcursal",
                          $$v
                        )
                      },
                      expression:
                        "dadosComplementaresCorretora.CodigoSurcursal",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.podeAlterarCodigoContrato
            ? _c(
                "div",
                { staticClass: "w-1/4 pr-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "label-placeholder": "Código do contrato",
                      size: "large",
                      maxlength: "10",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validarNumeros(
                          $event,
                          "dadosComplementaresCorretora.CodigoContrato"
                        )
                      },
                    },
                    model: {
                      value: _vm.dadosComplementaresCorretora.CodigoContrato,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dadosComplementaresCorretora,
                          "CodigoContrato",
                          $$v
                        )
                      },
                      expression: "dadosComplementaresCorretora.CodigoContrato",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.podeAlterarCanal
            ? _c(
                "div",
                {
                  staticClass: "w-1/4",
                  style: {
                    "align-self": "flex-end",
                    height: "48px !important",
                  },
                },
                [
                  _c("v-select", {
                    staticClass: "w-full",
                    attrs: {
                      label: "Descricao",
                      placeholder: "Selecione o canal",
                      size: "large",
                      value: _vm.dadosComplementaresCorretora.TipoCanalId,
                      reduce: (tipoCanal) => tipoCanal.Id,
                      options: _vm.tiposDeCanais,
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    model: {
                      value: _vm.dadosComplementaresCorretora.TipoCanalId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dadosComplementaresCorretora,
                          "TipoCanalId",
                          $$v
                        )
                      },
                      expression: "dadosComplementaresCorretora.TipoCanalId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    (_vm.dadosCorretora.SUSEPProtocol || "").length == 0
      ? _c("div", { staticClass: "vx-row px-12 mb-5" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full mt-12 p-0" },
            [
              _c(
                "vs-alert",
                {
                  attrs: {
                    title: "Recadastramento SUSEP",
                    color: "rgb(231, 154, 23)",
                    active: "true",
                  },
                },
                [
                  _vm._v(
                    "\n        Atenção, identificamos que você não está recadastrado na SUSEP.\n        Verifique seu cadastro no site da Superintendência de Seguros\n        Privados.\n        "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "http://susep.gov.br", target: "_blank" },
                    },
                    [_vm._v("http://susep.gov.br")]
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "vx-row px-12 mb-5 mt-12" }, [
      _c(
        "h4",
        { staticClass: "vx-col w-full flex items-center font-semibold p-0" },
        [
          _c("span", { staticClass: "block mb-2" }, [
            _vm._v("Dados do administrador do Portal"),
          ]),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.text.textAdministrador, position: "right" } },
            [_c("i", { staticClass: "material-icons pl-2" }, [_vm._v("info")])]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mt-5 p-0" },
        [
          _c("ValidationProvider", {
            attrs: { name: "Nome do administrador", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "Nome do administrador",
                        size: "large",
                      },
                      model: {
                        value: (_vm.dadosCorretora.Administrator || {}).Name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dadosCorretora.Administrator || {},
                            "Name",
                            $$v
                          )
                        },
                        expression: "(dadosCorretora.Administrator || {}).Name",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 p-0" },
        [
          _c("ValidationProvider", {
            attrs: { name: "E-mail", rules: "required|email" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "E-mail",
                        type: "email",
                        size: "large",
                      },
                      model: {
                        value: (_vm.dadosCorretora.Administrator || {}).Email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dadosCorretora.Administrator || {},
                            "Email",
                            $$v
                          )
                        },
                        expression:
                          "(dadosCorretora.Administrator || {}).Email",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "Celular",
              rules: {
                regex:
                  /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##) ####-####", "(##) #####-####"],
                          expression: "['(##) ####-####', '(##) #####-####']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "Celular para autenticação",
                        size: "large",
                      },
                      model: {
                        value: (_vm.dadosCorretora.Administrator || {})
                          .PhoneNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dadosCorretora.Administrator || {},
                            "PhoneNumber",
                            $$v
                          )
                        },
                        expression:
                          "(dadosCorretora.Administrator || {}).PhoneNumber",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }